<template>
    <section class="fade">
        <v-row class="primary-font fade pa-3 d-flex align-center justify-center">
            <v-col cols="12" md="4">
                <Login v-if="showLogin" @toRegister="showLogin = false" transition="fade-transition" class="my-10" :toCheckout="false"/> 
                <Register v-else-if="!showLogin" @toLogin="showLogin = true" transition="fade-transition"/> 
            </v-col>
            <v-col cols="12" md="4" class="d-flex mb-3 flex-column justify-center">
                <v-card class="border pt-3 pb-0 box-shadow-rd mr-3 text-center">

                    <div class="f20 fw600 px-3">
                        New to our <span class="l-primary--text">LMS</span>? 
                    </div>
                    <div class="f18 primary-l--text fw600 px-3">
                        Watch this video to see how it works! 
                    </div>
                    
                    <video controls width="100%" class="">
                        <source :src="`https://csi-test-s3-mdpie.s3.ap-southeast-1.amazonaws.com/csi_cNery/landing_page/csc_elearning_launching.mp4`">
                    </video>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" class="d-flex align-center pr-6">
                <HomeCard class="fade mb-3" :image="require(`@/assets/images/landing/background/csc-course-frame2.png`)" :courses="courses"/>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Login from "@/components/landing/forms/Login.vue";
import Register from "@/components/landing/forms/Register.vue";
import HomeCard from "@/components/landing/cards/home-card.vue";

export default {
    name: 'landing-home',
    components: {
        Login,
        Register,
        HomeCard,
    },
    data: () => ({
        showLogin: true,
        loading: false
    }),
    mounted() {
        this.loading = true
        this.getHomepageCourses().then(() => {
            this.loading = false
        }).catch(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions(['getHomepageCourses'])
    },
    computed: {
        ...mapState({
            courses: (state) => state.other_courses
        }),
    }
}
</script>